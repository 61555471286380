import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import Img1 from '../assets/team1.png';
import Img2 from '../assets/team2.png';
import Img3 from '../assets/team3.png';

import Icon from '../assets/icon.png';


const Card = ({ person }) => {
  return (
    <div className='py-11'>
    <div className="card ">
      <div className="card-border-top"></div>
      <div className="img">
        <img src= {person.image} alt=''/>
      </div>
      <span >{person.name}</span>
      <p className="job gradient-text ">{person.jobTitle}</p>

      <div className="icon">
        <img src= {person.icon} alt=''/>
      </div>
      
    </div>
    </div>
  );
};

const TeamList = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const persons = [
    { image : Img3, name: 'Alex', jobTitle: 'Jupy Cats Team' ,icon:Icon},
    { image : Img2, name: 'Senpai', jobTitle: 'Jupy Cats Team' ,icon:Icon},
    { image : Img1, name: 'Daryl', jobTitle: 'Jupy Cats Team' ,icon:Icon},
    
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % persons.length);
    }, 3000); // Change the interval time as needed (in milliseconds)

    return () => clearInterval(interval);
  }, [persons.length]);

  return (
    <div className="py-11 flex flex-col px-6 mb-7 relative overflow-hidden rounded-box" >
        <motion.h1
              variants={fadeIn('up', 0.3)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[37px] font-bold leading-[0.8] lg:text-[110px] gradient-text '>
              Our Team
            </motion.h1>
      <div
        className="flex  justify-around items-center flex-wrap "
        id='team'
      >
        {persons.map((person, index) => (
          <Card key={index} person={person} />
        ))}
      </div>
    </div>
  );
};

export default TeamList;
