import React from 'react';
import Bg from '../assets/banner2.png';

const Carousel = () => {
  return (
    <header className='py-11 w-full bg-[#e3e5cd]'>
      <div className='container mx-auto w-full '>


        <div className='flex w-full justify-center items-center'>
          <img src={Bg} alt='' className='w-full h-full object-cover' />
        </div>

      </div>


    </header>
  );
};

export default Carousel;