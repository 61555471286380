import React from 'react';
import Step2 from '../assets/step.png';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { FaTwitter } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";

const Contact = () => {
  return (

    <div
      className="flex flex-row lg:flex-col items-center h-[444px] lg:h-[277px] pb-[112px] pt-[112px] lg:pt-1 bg-back text-center text-white  ">


      <div className="container px-6 pt-6 flex flex-row items-center justify-between pb-11">

        <div className='flex justify-start items-start mb-[-130px] pr-[45px] flex-col gap-y-8 lg:flex-col lg:items-center lg:gap-x-12 '>



          <img src={Step2} alt='' className='w-[120px] h-[120px]  object-cover' />



        </div>
        <div className=" flex justify-center">
          <motion.div
            variants={fadeIn('up', 0.8)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.7 }}
            className='flex  text-[20px] gap-x-6 max-w-max mx-auto
            lg:mx-0'>
            <a href="https://discord.gg/jupycats" className='cursor-pointer' >
              <BsDiscord className='w-11 h-11 cursor-pointer' />
            </a>
            <a href="https://x.com/jupycats?s=21&t=4WQeqNGb0_ZES0JXVWhm_w" className='cursor-pointer' >
              <FaTwitter className="w-11 h-11 cursor-pointer" />
            </a>

          </motion.div>
        </div>
        <div
          className=" "
        >
            © 2024 Copyright:
          <a className="text-white" href=" ">Jupy Cats</a>
        </div>


      </div>
    </div>
  );
};

export default Contact;
