import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import Logo from '../assets/about.png';



const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section bg-back ' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* img */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1  bg-contain bg-no-repeat h-[640px] min-blend-lighten bg-top' >
            <img src={Logo} alt='' />
          </motion.div>

          {/* text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.7 }}
            className='flex-1'>

            <h2 className='h2 gradient-text text-6xl' id='about'>Join Us.</h2>
            <h3 className='h3 mb-4 text-gray-300'>as we paint a masterpiece to express our love and support for the incredible journeys fueled by the magic of
              @JupiterExchange</h3>


          </motion.div>
        </div>
      </div>
    </section>
  );

};

export default About;
