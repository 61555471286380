import React from 'react';
import Image from '../assets/gigig.gif';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';


const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' >
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>

          <div className='flex justify-between items-center mb-[-130px] flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12 '>





          </div>
          {/* text */}
          <div className='flex-1 text-center pt-9 font-secondary lg:text-left' id='banner'>
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className=' gradient-text text-[55px] font-bold leading-[0.8] lg:text-[110px] '>
              Jupy <span>Cats</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.5)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-9 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-[#a4a87d] first:mr-4'>We Are a</span>
              <TypeAnimation
                sequence={[
                  'Nft Builders',
                  2000,
                  'Art Makers',
                  2000,
                ]}
                speed={50}
                className='text-accent'
                wrapper='span'
                repeat={Infinity}
              />
            </motion.div>


          </div>

          {/* image */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            className=' lg:flex flex-1 max-w-[320px] lg:max-w-[480px] '>
            <img src={Image} alt='GIF' />
          </motion.div>
        </div>
      </div>
    </section>
  )
};

export default Banner;
