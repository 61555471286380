import React, { useState, useRef, useEffect } from 'react';
import Logo from '../assets/gif1.gif';
// import Step from '../assets/step.png';
// import Step2 from '../assets/step2.png';
import myAudio from './Cat-sound-meow.mp3';
import { Link } from 'react-scroll';
const Header = () => {

  // State to track the audio playback status
  const [isPlaying, setIsPlaying] = useState(false);

  // Reference to the audio element
  const audioRef = useRef(null);


  useEffect(() => {
    // Check if audioRef is not null before accessing its properties
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      }
    }
  }, [isPlaying]);

  // Event handler for the play/pause button click
  const handleTogglePlay = () => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  return (
    <header className='bg-back shadow' id='home'>
      <div className='container mx-auto '>
       
        <div className='flex justify-around items-center flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          {/* logo */}
          <div>
            <img src={Logo} alt='GIF' />
          </div>
          {/* button */}
          {/* Audio element */}
          <audio ref={audioRef} src={myAudio} />

          <Link
            to='banner'
            activeClass=' '
            smooth={true}
            spy={true}
            offset={-200}
            className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>

            <div class="item-hints  mb-[200px]" onClick={handleTogglePlay}>
              <div class="hint" data-position="4">
                <span class="hint-radius"></span>
                <span class="hint-dot">Meow</span>
                <div class="hint-content do--split-children">
                  <p>Let's Gooo :)</p>
                </div>

              </div>

            </div>



          </Link>
        </div>



      </div>

    </header>
  )
};

export default Header;