import React from 'react';


// components
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';
import Carousel from './components/Carousel';
import Carousel1 from './components/Carousel1';
import Carousel2 from './components/Carousel2';
import Carousel3 from './components/Carousel3';
import TeamList from './components/Team';



const App = () => {



  return (
    <div className='bg-[#e3e5cd] bg-no-repeat bg-cover overflow-hidden'>
      <Header />
      <Banner />
      <Carousel3 />
      <Carousel />
      <Carousel1 />
      <Carousel2 />
      <TeamList />
      <Nav />
      <About />
      <Services />
      <Work />
      <Contact />
      
    </div>
  );
};

export default App;
