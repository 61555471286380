import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';

import { LuCat } from "react-icons/lu"
import { BsEyeFill } from "react-icons/bs";
import { GiMusicalNotes } from 'react-icons/gi';
import { GiThreeFriends } from "react-icons/gi";
import { FaInfoCircle } from "react-icons/fa";
import myAudio from './cat_soo.mp3';

const Nav = () => {


  // State to track the audio playback status
  const [isPlaying, setIsPlaying] = useState(false);

  // Reference to the audio element
  const audioRef = useRef(null);


  useEffect(() => {
    // Check if audioRef is not null before accessing its properties
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying]);

  // Event handler for the play/pause button click
  const handleTogglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <nav className='fixed bottom-2 lg:bottom-8 w-full z-50'>
      <div className='cursor-pointer w-[60px] h-[60px] btn color-[#eee] rounded-full flex items-center justify-center ml-6'>
        <div className='group relative'>
          <audio ref={audioRef} src={myAudio} autoPlay />
          <GiMusicalNotes onClick={handleTogglePlay} />
          <span class="absolute -top-14 left-[50%] -translate-x-[50%] 
  z-50 origin-left scale-0 px-3 rounded-lg border 
  border-gray-300 bg-black py-2 text-sm font-bold
  shadow-md transition-all duration-300 ease-in-out 
  group-hover:scale-100">Music<span>
            </span></span>
        </div>
      </div>




      <div className='container mx-auto'>

        {/* nav inner */}
        <div className='w-full bg-black/20 h-[96px] backdrop-blur-2xl rounded-full max-w-[460px] mx-auto px-5 flex  text-2xl text-white/50 items-center justify-center'>
          <Link
            to='home'
            activeClass='active'
            smooth={true}
            spy={true}
            offset={-200}
            className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>

            <div className='group relative'>
              <LuCat />
              <span class="absolute -top-14 left-[50%] -translate-x-[50%] 
  z-50 origin-left scale-0 px-3 rounded-lg border 
  border-gray-300 bg-black py-2 text-sm font-bold
  shadow-md transition-all duration-300 ease-in-out 
  group-hover:scale-100">Home<span>
                </span></span>
            </div>
          </Link>

          <Link
            to='sneak'
            activeClass='active'
            smooth={true}
            spy={true}
            offset={-200}
            className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
            <div className='group relative'>
              <BsEyeFill />
              <span class="absolute -top-14 left-[50%] -translate-x-[50%] 
  z-1 origin-left scale-0 px-3 rounded-lg border 
  border-gray-300 bg-black py-2 text-sm font-bold
  shadow-md transition-all duration-300 ease-in-out 
  group-hover:scale-100">Sneak Peek<span>
                </span></span>
            </div>
          </Link>
          <Link
            to='team'
            activeClass='active'
            smooth={true}
            spy={true}
            offset={-200}
            className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
            <div className='group relative'>
              <GiThreeFriends />
              <span class="absolute -top-14 left-[50%] -translate-x-[50%] 
  z-1 origin-left scale-0 px-3 rounded-lg border 
  border-gray-300 bg-black py-2 text-sm font-bold
  shadow-md transition-all duration-300 ease-in-out 
  group-hover:scale-100">Our Team<span>
                </span></span>
            </div>
          </Link>


          <Link
            to='about'
            activeClass='active'
            smooth={true}
            spy={true}
            offset={-200}
            className='cursor-pointer w-[60px] h-[60px] flex items-center justify-center'>
            <div className='group relative'>
              <FaInfoCircle />
              <span class="absolute -top-14 left-[50%] -translate-x-[50%] 
  z-1 origin-left scale-0 px-3 rounded-lg border 
  border-gray-300 bg-black py-2 text-sm font-bold
  shadow-md transition-all duration-300 ease-in-out 
  group-hover:scale-100">About Us<span>
                </span></span>
            </div>

          </Link>
        </div>

      </div>
    </nav>
  )
};

export default Nav;
